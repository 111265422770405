import React from "react"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

import oque from "../../images/servicos/svg/OQueE.svg"
import quem from "../../images/servicos/svg/QuemPodeUsar.svg"
import trata from "../../images/servicos/svg/Tratamento.svg"

import Img from "gatsby-image"

const Info = ({ data }) => {
  return (
    <StyledInfo>
      <div className="passo">
        <div className={font.rEBI + " subtitle"}>{data.aparelho.title1}</div>
        <div className={font.rR + " txt"}>{data.aparelho.text1}</div>
        <br />
        <br />
        <div className={font.rEBI + " subtitle"}>
          {data.aparelho.lavagemTitle}
        </div>
        <div className={font.rR + " txt"}>
          {data.aparelho.lavagemTxt.map((data, i) => (
            <div key={"lavagem" + i}>{data}</div>
          ))}
        </div>
      </div>

      <div className="passo">
        <div className="invisalign-image">
          <Img
            fluid={data.invisalign.image.childImageSharp.fluid}
            alt={data.invisalign.title}
            title={data.invisalign.title}
          />
        </div>
        <div className={font.rSB + " subtitle invisalign"}>
          {data.invisalign.title[1]}
        </div>
        <p className={font.rSB + " title"}>
          <span className="smallUnderline" />
        </p>
        <br />
        <br />
        <div className="instruc">
          <img src={oque} alt="O que é" className="iconBig" />
          <br />
          <img src={quem} alt="Quem pode usar" className="iconBig" />
          <br />
          <img src={trata} alt="Tratamento" className="iconBig" />
        </div>
        <div className={font.rB + " title final-video"}>
          {data.videoFinal.title}
        </div>
        {/* eslint-disable-next-line */}
        <video playsInline controls poster={data.videoFinal.poster.publicURL}>
          <source src={data.videoFinal.video.publicURL} type="video/mp4" />
        </video>
      </div>
    </StyledInfo>
  )
}

export default Info

const StyledInfo = styled.div`
  position: relative;
  margin: 5vw auto calc(100px + 100 * (100vw - 320px) / 1600);
  width: calc(280px + 800 * (100vw - 320px) / 1600);
  @media only screen and (min-width: 1920px) {
    width: 1080px;
    margin: 5vw auto 200px;
  }
  .title {
    font-size: calc(16px + 22 * (100vw - 320px) / 1600);
    @media only screen and (min-width: 1920px) {
      font-size: 38px;
    }
    letter-spacing: 0.1em;
    color: #231f20;
    text-transform: uppercase;
    margin-bottom: 2em;
    position: relative;
  }
  .subtitle {
    font-size: calc(14px + 10 * (100vw - 320px) / 1600);
    @media only screen and (min-width: 1920px) {
      font-size: 24px;
    }
    letter-spacing: 0.1em;
    line-height: 1.6em;
    color: #231f20;

    &.invisalign {
      font-size: calc(14px + 20 * (100vw - 320px) / 1600);
      text-transform: uppercase;
    }
  }
  .smallUnderline {
    width: 2em;
    height: 0.25em;
    background-color: #b7a99a;
    position: absolute;
    top: 0.6em;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .txt {
    font-size: calc(12px + 12 * (100vw - 320px) / 1600);
    @media only screen and (min-width: 1920px) {
      font-size: 22px;
    }
    letter-spacing: 0.1em;
    line-height: 1.6em;
    color: #808080;
  }
  .passo {
    margin: calc(30px + 20 * (100vw - 320px) / 1600) 0;
    @media only screen and (min-width: 1920px) {
      margin: 50px 0;
    }
  }
  .titleFlex {
    display: flex;
    align-items: center;
  }
  .icon {
    width: calc(66px + 34 * (100vw - 320px) / 1600);
    @media only screen and (min-width: 1920px) {
      width: 100px;
    }
  }
  .instruc {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .invisalign-image {
    margin: 5vw 13vw 0;
  }
  .invisalign {
    text-align: center;
  }
  .iconBig {
    width: 800px;
    margin-top: 20px;
    max-width: 90vw;

    @media only screen and (max-width: 420px) {
      margin-top: 5px;
    }
  }
  .marginBot {
    margin-bottom: 25px;
  }
  .marginRight {
    margin-right: 15px;
  }
  .final-video {
    margin: 2em 0 2em;
    text-align: center;
  }
  .final-video ~ video {
    width: 100%;
  }
`
