import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Navbar from "../../components/navbar/navbar"
import NavbarMobile from "../../components/navbar/navbarMobile"
import Intro from "../../components/servicos/intro"
import IntroMobile from "../../components/servicos/introMobile"
import Banner from "../../components/banner/bannerOrto"
import Info from "../../components/servicos/infoOrto"
import Footer from "../../components/footer/footer"
import FooterMobile from "../../components/footer/footerMobile"
import FormServicos from "../../components/servicos/formServicos"
import FormServicosMobile from "../../components/servicos/formServicosMobile"

const OrtodontiaPage = ({ data }) => (
  <Layout>
    <SEO title="Ortodontia" description={data.ortodontiaJson.intro.text} />
    {useBreakpoint().mobile ? (
      <>
        <NavbarMobile />
        <IntroMobile data={data.ortodontiaJson.intro} video src={data.ortodontiaJson.content.videoBanner} />
        <Banner data={data.ortodontiaJson.banner} />
        <Info data={data.ortodontiaJson.content} />
        <FormServicosMobile nome="Ortodontia" />
        <FooterMobile data={data.globalJson.footer} />
      </>
    ) : (
      <>
        <Navbar />
        <Intro data={data.ortodontiaJson.intro} video src={data.ortodontiaJson.content.videoBanner} />
        <Banner data={data.ortodontiaJson.banner} />
        <Info data={data.ortodontiaJson.content} />
        <FormServicos nome="Ortodontia" />
        <Footer data={data.globalJson.footer} />
      </>
    )}
  </Layout>
)

export default OrtodontiaPage

export const Json = graphql`
  query ortodontia {
    ortodontiaJson {
      intro {
        title
        text
      }
      banner {
        imgs {
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 4000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
      }
      content {
        aparelho {
          title1
          text1
          lavagemTitle
          lavagemTxt
        }
        invisalign {
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          questoes {
            title
            text
          }
        }
        videoBanner {
          video {
            publicURL
          }
          poster {
            publicURL
          }
        }
        videoFinal {
          title
          video {
            publicURL
          }
          poster {
            publicURL
          }
        }
      }
    }
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
  }
`
