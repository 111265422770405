import React from "react"
import Img from "gatsby-image"
/* import { Link } from "gatsby" */
import Slider from "react-slick"

import styled from "styled-components"
/* import font from "../../fonts/fonts.module.scss" */

/* import seta from "../../images/homepage/seta.svg" */

const Banner = ({ data }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
  }

  return (
    <StyledBanner bg={data.bg}>
      <div className="bannerContent">
        <Slider {...settings}>
          {data.imgs.map((data, i) => (
            <div className="bannerImg" key={"equipa-slide" + i}>
              <Img
                fluid={data.img.childImageSharp.fluid}
                style={{ width: `100%`, height: "100%" }}
                imgStyle={{ objectFit: "cover" }}
                alt={data.alt && data.alt}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="bg" />
    </StyledBanner>
  )
}

export default Banner

const StyledBanner = styled.div`
  position: relative;
  margin: 2vw 0 0;
  width: 100%;
  .bannerContent {
    position: relative;
    width: calc(320px + 1298 * (100vw - 320px) / 1600);
    max-width: 100vw;
    margin: 0 auto calc(60px + 60 * (100vw - 320px) / 1600);

    @media only screen and (min-width: 1920px) {
      width: 1618px;
      margin: 0 auto 120px;
    }

    .bannerImg {
      height: 100%;
      width: 100%;
      position: relative;
    }
  }

  .slick-slider {
    line-height: 0;
  }
  .slick-track {
    display: flex;
  }
  .slick-list {
    overflow: hidden;
  }
`
